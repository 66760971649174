/* App.css */

.skeleton-custome {
    background-color: #ddd !important;
    height: 30px;
    width: 100%;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.skeleton-custome::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    // background: linear-gradient(90deg,
    //         transparent,
    //         rgba(255, 255, 255, 0.2),
    //         transparent);
    // animation: shimmer 2s infinite;
    background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200px 100%;
  animation: shimmer 2s infinite;

}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}