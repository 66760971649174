.email-phone-style {
    border-radius: 9px;
    border: 2px solid rgba(107, 107, 107, 0.12);
    background: #FFF;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.email-phone-input {
    border-radius: 0px 9px 9px 0px;
    border: 2px solid rgba(107, 107, 107, 0.12);
    background: #FFF;
}

.join-as-style {
    border-radius: 9px;
    border: 2px solid rgba(107, 107, 107, 0.12);
    background: rgb(255, 255, 255);
    padding: 10px 6px;
    width: 100%;
}

.password-input {
    border-radius: 9px 0px 0px 9px;
    border: 2px solid rgba(107, 107, 107, 0.12);
    background: #FFF;
}

.password-toggle-icon {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 100% !important;
    background: none !important;
    border: 2px solid rgba(107, 107, 107, 0.12) !important;
    border-left: none !important;
    font-size: 17px !important;
}

.text-inside-input-field {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 100% !important;
    background: none !important;
    border: 2px solid rgba(107, 107, 107, 0.12) !important;
    border-left: none !important;
    font-size: 12px !important;
    color: #2171d9 !important;
    font-weight: 700 !important;
}

.comment-box {
    flex-direction: column;
    justify-content: flex-start;
    padding: 6px 0px;
    margin-bottom: 15px;

}

.text-area-heading {
    font-size: 14px;
    font-weight: 700;
    color: grey;
    padding-bottom: 6px;
}

.text-area-textarea {
    height: 120px !important;
    padding: 10px !important;
    border: 2px solid rgba(107, 107, 107, 0.12) !important;
}

.search-field {
    border: 2px solid rgba(107, 107, 107, 0.12) !important;
    border-right: none !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.search-icon {
    border: 2px solid rgba(107, 107, 107, 0.12) !important;
    font-size: 19px !important;
    background: white !important;
    border-left: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.skip-btn {
    background: #8080802e !important;
    border: none !important;
    color: black !important;
    font-weight: 600 !important;
    padding: 5px 10px !important;
    border-radius: 0px !important;
    transition: all 0.3s ease-in-out;
}

.skip-btn:hover {
    background: #80808073 !important;
}

.passwordfieldleft {
    z-index: 2;
    background: transparent;
    border-right: none;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.password-eye {
    font-size: 18px;
}

.custom-select-menu {
    z-index: 5 !important;
}

.activatedLabel{
    // color:var(--primaryColor) ;
}

.form-control {
    border: 2px solid;
    // border-color: $primary;
  }
  
  .form-control:focus {
    box-shadow: none;
  }
  
  .textfield-label {
    position: absolute;
    transition: top 0.2s, left 0.2s;
    line-height: 1rem;
  }
  
  .textfield-label-unactivated {
    left: 1rem;
    top: 0.9rem;
    z-index: 4;
    color: "#a09c9c";
  }
  
  .textfield-label-activated {
    left: 0.75rem;
    top: -0.5rem;
    z-index: 1010;
    padding: 0px 2px;
   color: var(--primary);
  }
  
  .textfield-label-cover {
    position: absolute;
    transition: top 0.2s, left 0.2s;
    line-height: 1rem;
  }
  
  .textfield-label-cover-unactivated {
    left: 1rem;
    top: 0.9rem;
    background-color: white;
  }
  
  .textfield-label-cover-activated {
    left: 0.75rem;
    opacity: 1;
    z-index: 1009;
    background-color: white;
    padding: 0px 2px;
    height: 2px;
  }
  