/* 

.fc {
    color: #ecf0f1;}

.fc-day-number {
    font-size: 1.2em !important;
    color: #ecf0f1 !important; 
}

.fc-header-toolbar {
    color: #ecf0f1;
}

.fc-daygrid-day-top {
    color: var(--primary);
    font-size: 14px;
    font-weight: 600;

}

.fc-daygrid-event {
    color: #ffffff;
}


.fc-daygrid-day-frame {
    border: 1px solid lightgray !important; 
}
.fc-daygrid-day-number {
 color:var(--primary)
} */

.fc-event {
    cursor: pointer !important;
}
